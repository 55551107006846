import axios from "axios";
export const urlBase = "";
// export const urlBase = 'http://localhost:8089'
// export const urlBase = "https://ag-platform.com";
// export const urlBase = "https://ag-platform.com";
// export const urlBase= 'http://5.182.18.247:8073'

// //---- get Adm----
// export const getAccounts = () => {
//   return axios({
//     method: "get",
//     url: `${urlBase}/api/admins`,
//     responseType: "json",
//   });
// }; 

// // ----------Register--------
// export const register = (data) => {   // role used to identefy type of user to register
//     const url = `${urlBase}/api/register/user`;
//     return axios({
//       method: "POST",
//       data: data,
//       withCredentials: true,
//       url: url,
//     });
//   };

// // Reset Password  request
// export const ResetPassRequest= (email)=>{
//   return axios({
//     method:"POST",
//     data:email,
//     withCredentials:true,
//     url:`${urlBase}/api/user/password/reset`
//   })
// }

// // Verify Reset Code
// export const verifyCodeRequest = (code)=>{
//   return axios({
//     method:"POST",
//     data:code,
//     withCredentials:true,
//     url:`${urlBase}/api/user/verify/code`
//   })
// }

// //--------check login statue when reload or new session-----------
// export const intializeUser = () => {
//     return fetch(`${urlBase}/api/auth`, {
//       credentials: "include",
//     })
//       .then((res) => res.json())
//       .then((user) => user)
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   //------- logOut --------
//   export const logOut = () => {
//     return fetch(`${urlBase}/api/logout`, {
//       credentials: "include",
//     });
//   };

//   // --------  login------
//   export const getAuthedUser = (authentcation) => {
//     return axios({
//       method: "POST",
//       data: {
//         username: authentcation.email,
//         password: authentcation.password,
//       },
//       withCredentials: true,
//       url: `${urlBase}/api/login`,
//     }).then((res) => res.data);
//   };
//   // update user
//   export const update_user=(data)=>{//data is an object
//     // console.log('api',data,role)
//     return axios({
//       method:"put",
//       data:{property:data},
//       withCredentials:true,
//       url:`${urlBase}/api/update`//update client end point
//     })
//   }

export const registerUser = (data) => {
  return axios({
    method: "POST",
    url: `${urlBase}/api/auth/signup`,
    data: data
  })
}
export const registerUserAdmin = (data) => {
  const token = localStorage.getItem('token');
  return axios({
    method: "POST",
    url: `${urlBase}/api/users`,
    data: data,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}


export const loginUser = (data) => {
  return axios({
    method: "POST",
    url: `${urlBase}/api/auth/login`,
    data: data,
  })
}

// Get Clients
export const getAllClients = (data) => {
  const token = localStorage.getItem('token');
  return axios({
    method: "GET",
    url: `${urlBase}/api/users?role=client`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}
// Get All Users Clients
export const getAllUsers = (page = 1, limit = 5, filters = {}) => {
  const token = localStorage.getItem('token');
  const params = {
    page: page,
    limit: limit,
  };
  if (filters.role) {
    params.role = filters.role;
  }

  return axios({
    method: "GET",
    url: `${urlBase}/api/users`,
    params: params,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

// Update My Profile
export const updateProfile = (data) => {
  const token = localStorage.getItem('token');
  return axios({
    method: "PATCH",
    url: `${urlBase}/api/users/me`,
    data: data,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}