import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Register_New_User_Admin } from '../../../actions/signin-up';
import { ErrorMsg, success } from '../../shared/Modules/Toast';
import { HomePage } from '../../../translation/trans';

const AddEmployee = () => {
    const { lang, dir } = useSelector(({ language }) => ({
        lang: language.lang,
        dir: language.dir,
    }));

    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [job, setJob] = useState('');
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState('');
    const dispatch = useDispatch();

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handlePhone = (valuePhone) => {
        setPhone(valuePhone);
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const resRegister = await dispatch(
                Register_New_User_Admin({
                    name,
                    email,
                    password: pass,
                    role,
                    phone: `+${phone}`,
                    country,
                    address,
                    job,
                    status: 'active',
                })
            );
            if (resRegister?.status === 201) {
                success('تم التسجيل بنجاح');
                handleClose();
            }
        } catch (e) {
            ErrorMsg(e?.response?.data?.message?.join('-') || e?.message);
            console.error(e?.message)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <button className="btn login" onClick={handleShow}>
                اضافة موظف
            </button>
            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <Modal.Body>
                    <form onSubmit={handleRegister}>
                        <div>
                            <div className="mt-1">
                                <label>{HomePage.email[lang]}</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-1">
                                <label>{HomePage.username[lang]}</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-1">
                                <label>{HomePage.password[lang]}</label>
                                <input
                                    type="password"
                                    name="pass"
                                    className="form-control"
                                    value={pass}
                                    onChange={(e) => setPass(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-1">
                                <label>{HomePage.phone[lang]}</label>
                                <PhoneInput
                                    country="eg"
                                    value={phone}
                                    onChange={handlePhone}
                                    inputClass="form-control"
                                />
                            </div>
                            <div className="mt-1">
                                <label>{HomePage.country[lang]}</label>
                                <input
                                    type="text"
                                    name="country"
                                    className="form-control"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                            </div>
                            <div className="mt-1">
                                <label>{HomePage.address[lang]}</label>
                                <input
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div className="mt-1">
                                <label>{HomePage.role[lang]}</label>
                                <select
                                    name="role"
                                    className="form-control"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    required
                                >
                                    <option value="" disabled hidden>
                                        Choose Role
                                    </option>
                                    <option value="employee">Employee</option>
                                    <option value="client">Client</option>
                                </select>
                            </div>
                            <div className="mt-1">
                                <label>{HomePage.job[lang]}</label>
                                <input
                                    type="text"
                                    name="job"
                                    className="form-control"
                                    value={job}
                                    onChange={(e) => setJob(e.target.value)}
                                />
                            </div>
                            <div className="mt-3">
                                <button
                                    type="submit"
                                    className="btn"
                                    style={{ backgroundColor: '#4B76C0', color: 'white' }}
                                    disabled={loading}
                                >
                                    {loading ? HomePage.loading[lang] : HomePage.register[lang]}
                                </button>
                                <button
                                    type="button"
                                    className="btn mx-2"
                                    style={{ border: '1px solid #4B76C0', color: '#4B76C0' }}
                                    onClick={handleClose}
                                    disabled={loading}
                                >
                                    {HomePage.cancel[lang]}
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddEmployee;
