import React, { useRef } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { HomePage } from '../../translation/trans';
import { ErrorMsg, success } from '../shared/Modules/Toast';
import { handleAddContact } from '../../utils/contacts';
import serialize from 'form-serialize'
const StartService = () => {
    const [show, setShow] = useState(false);
    const [phone, setPhone] = useState("");
    const [whatsApp, setWhatsApp] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const formRef = useRef(null)
    const handlePhone = (valuePhone) => {
        setPhone('+'+valuePhone)
    }
    const handleWhatsApp = (valuePhone) => {
        setWhatsApp('+'+valuePhone)
    }
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    const serviceRed = useSelector((state) => state.serviceReducer);


    const onSubmit = async(e)=>{
        try{
       e.preventDefault();
       const data = serialize(formRef.current,{hash:true});
       data.phone=phone;
       data.whatsapp=whatsApp;
       console.log(data)
       const res = await handleAddContact(data);
       if(res.status===201){
        success(HomePage?.RequestServiceResponseSuccess[lang])
        handleClose()
       }
 
        }
        catch(e){
            console.error(e)
            ErrorMsg(e?.response?.data?.message?.join(' , '))
        }
    }
    return (
        <div>
            <button className='btn sharedBtn sharedHover' onClick={handleShow}>
                {HomePage.statNow[lang]}
            </button>
            <Modal show={show} onHide={handleClose} centered dir={dir}>
                <div style={{ backgroundColor: "#4973BC", color: "white", textAlign: "center" }}>
                    <p style={{ textAlign: "center", padding: "10px" }}>{HomePage.serviceFormTitle[lang]}</p>
                </div>
                <form ref={formRef} onSubmit={onSubmit} className="modalServicesBody">
                    <div>
                        <div>
                            <label>{HomePage.name[lang]}</label>
                            <input type="text" name="name" id="name" className="form-control" />
                        </div>
                        <div>
                            <label>{HomePage.email[lang]}</label>
                            <input type="email" name="email" id="email" className="form-control" />
                        </div>
                        <div>
                            <label>{HomePage.whatsapp[lang]}</label>
                            <PhoneInput
                                country={'eg'}
                                className="inputs"
                                style={{ width: "100%" }}
                                value={whatsApp}
                                onChange={(phone) => handleWhatsApp(phone)}
                            />
                        </div>
                        <div>
                            <label>{HomePage.phone[lang]}</label>
                            <PhoneInput
                                country={'eg'}
                                className="inputs"
                                style={{ width: "100%" }}
                                value={phone}
                                onChange={(phone) => handlePhone(phone)}
                            />
                        </div>
                        <div>
                            <label>{HomePage.services[lang]}</label>
                            <select className="form-control" name='service[]' multiple>
                                <option hidden>{HomePage.selectService[lang]}</option>
                                {
                                    serviceRed?.map((service, index) => (
                                        <option key={service?._id} value={service?._id}>{service?.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div>
                            <label>{HomePage.message[lang]}</label>
                            <textarea type="text" name="message" id="message" className="form-control" />
                        </div>
                    </div>
                    <div className="mt-4" style={{ textAlign: "left" }}>
                        <button  className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }}>
                            {HomePage.cancel[lang]}
                        </button>
                        <button type='submit'  className='btn' style={{ backgroundColor: "#4973BC", color: "white" }}>
                            {HomePage.Send[lang]}
                        </button>
                    </div>
                </form>



            </Modal>
        </div>
    )
}

export default StartService
