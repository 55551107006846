import React, { useEffect, useState } from 'react';
import LogoRhaya from '../../assests/footerLogo.png'
import Container from 'react-bootstrap/Container';
import { FaSearch } from "react-icons/fa";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "../../styles/shared.css";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Get_All_services } from '../../actions/serviceAction';
import { setLang } from '../../actions/lang';
import { HomePage } from '../../translation/trans';

const HeaderDefault = () => {

  const [user, setUser] = useState(null);
  // useEffect(() => {
  //   if (localStorage.getItem("user")) {
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     setUser(user);
  //   }
  //   dispatch(Get_All_services())
  // }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
      } catch (error) {
        console.log("Invalid user data in localStorage");
        localStorage.removeItem("user"); // Clear invalid data
      }
    }
    dispatch(Get_All_services());
  }, []);

  const navigate = useNavigate();

  const logOut = () => {
    navigate("/");
    window.location.href = "/"
    localStorage.clear()
    setUser(null);

  }
  const dispatch = useDispatch();

  const services = useSelector((state) => state.serviceReducer);


  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <>
      <div className="header" dir={dir}>
        <div>
          <Navbar expand="lg" className="" style={{ background: "transparent" }}>
            <Container>
              <Link to="/">
                <img src={LogoRhaya} alt="osbash" loading='lazy' style={{ width: "80px" }} />
              </Link>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav className="mx-auto" navbarScroll>
                  <NavLink to="/" className="linkNav" style={{ color: "#1A3667", textDecoration: "none" }}>
                    {HomePage.home[lang]}
                  </NavLink>

                  <Dropdown className="serviceTap">
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ color: "#1A3667", backgroundColor: "transparent", border: "none", boxShadow: "none", marginRight: "10px" }}>
                      {HomePage.services[lang]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        Array.isArray(services) && services.length > 0 ? (
                          services.map((service, index) => {
                            return (
                              <Dropdown.Item as={NavLink} to={`/services/${service._id}`} key={index}>
                                {service.name}
                              </Dropdown.Item>
                            );
                          })
                        ) : (
                          <Dropdown.Item as={NavLink} to={`/`} key="no-services">
                            No Services Exist yet
                          </Dropdown.Item>
                        )
                      }

                    </Dropdown.Menu>
                  </Dropdown>

                  <NavLink to="/products" className="linkNav" style={{ color: "#1A3667", textDecoration: "none", marginRight: "10px" }}>
                    {HomePage.products[lang]}
                  </NavLink>
                  <NavLink to="/works" className="linkNav" style={{ color: "#1A3667", textDecoration: "none", marginRight: "10px" }}>
                    {HomePage.works[lang]}
                  </NavLink>
                  <NavLink to="/about-company" className="linkNav" style={{ color: "#1A3667", textDecoration: "none", marginRight: "10px" }}>
                    {HomePage.about[lang]}
                  </NavLink>
                  <NavLink to="/contact-us" className="linkNav" style={{ color: "#1A3667", textDecoration: "none", marginRight: "10px" }}>
                    {HomePage.contactUs[lang]}
                  </NavLink>
                  <NavLink to="/blog" className="linkNav" style={{ color: "#1A3667", textDecoration: "none", marginRight: "10px" }}>
                    {HomePage.blog[lang]}
                  </NavLink>
                  <select className="form-control" style={{ background: "transparent", width: "200px", marginRight: "10px", marginTop: "-5px" }} onChange={(e) => {
                    dispatch(setLang(e.target.value));
                  }}
                    value={lang}>
                    <option hidden>Select Language</option>
                    <option>EN</option>
                    <option>AR</option>
                  </select>
                </Nav>
                {/* {
                  user?.role === "admin" ? (
                    <>
                      <Link to={`/dashboard/${user?._id}`} className="btn login mx-2 ">لوحة التحكم</Link>
                      <Link to="" className="btn login" onClick={logOut} style={{ color: "white" }}>تسجيل الخروج</Link>
                    </>
                  ) : (<Link to="/register" className="btn login">

                    تسجيل الدخول

                  </Link>)
                } */}
                {user ? (
                  <>
                    {user.role === "admin" && (
                      <Link to={`/dashboard/${user._id}`} className="btn login mx-2">
                        {HomePage.dashboard[lang]}
                      </Link>
                    )}
                    {user.role === "client" && (
                      <Link to={`/dashboard/${user._id}/client`} className="btn login mx-2">
                        {HomePage.dashboard[lang]}
                      </Link>
                    )}
                    <Link
                      to=""
                      className="btn login"
                      onClick={logOut}
                      style={{ color: "white" }}
                    >
                      {HomePage.logOut[lang]}
                    </Link>
                  </>
                ) : (
                  <>
                  <Link to="/register" className="btn login">
                    {HomePage.register[lang]}
                  </Link>
                  <Link to="/login" className="btn login">
                    {HomePage.login[lang]}
                  </Link>
                  </>
                )}

              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default HeaderDefault;



// import React from 'react';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Button from 'react-bootstrap/Button';
// import { Link, NavLink } from 'react-router-dom';
// import Image from 'react-bootstrap/Image';
// import LogoRhaya from '../../assests/footerLogo.png'; // تأكد من وجود صورة هنا
// import "../../styles/shared.css";

// const HeaderDefault = () => {
//   return (
//     <Navbar expand="lg" style={{ background: "transparent" }}>
//       <Container>
//         <Navbar.Brand>
//           <Image src={LogoRhaya} style={{ width: "100px", height: "80px" }} alt="rahya" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="navbarScroll" />
//         <Navbar.Collapse id="navbarScroll" className="justify-content-between">
//           <Nav className="mx-auto">
//             <NavLink to="/" style={{ color: "#1A3667", textDecoration: "none", margin: "0 10px" }}>Tab1</NavLink>
//             <NavLink to="/services" style={{ color: "#1A3667", textDecoration: "none", margin: "0 10px" }}>Tab2</NavLink>
//             <NavLink to="/contact" style={{ color: "#1A3667", textDecoration: "none", margin: "0 10px" }}>Tab3</NavLink>
//           </Nav>
//           <Button variant="success" style={{ backgroundColor: "#1A3667", borderColor: "#1A3667" }}>زر</Button>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default HeaderDefault;
