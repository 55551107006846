export const HomePage = {
    // NavBar
    home: {
        AR: ' الرئيسيه',
        EN: 'Home',
    },
    services: {
        AR: ' الخدمات',
        EN: 'Services',
    },
    products: {
        AR: "منتجاتنا",
        EN: "Our Products"
    },
    works: {
        AR: "اعمالنا",
        EN: "Our Works"
    },
    about: {
        AR: "من نحن",
        EN: "About Us"
    },
    contactUs: {
        AR: "تواصل معنا",
        EN: "Contact Us"
    },
    blog: {
        AR: "المدونة",
        EN: "Blogs"
    },
    dashboard: {
        AR: "لوحة التحكم",
        EN: "Dashboard"
    },
    logOut: {
        AR: "تسجيل الخروج",
        EN: "Log Out"
    },


    // Home Header Home Page
    infoOne: {
        AR: "أوسباش لخدمات وحلول تكنولوجيا المعلومات",
        EN: "OSBASH IT Services and Solutions"
    },
    infoTwo: {
        AR: `نلبي أحتياجات العملاء من خلال تقديم حلول مبتكرة ومتطورة تعتمد على التكنولوجيا الرقمية
`,
        EN: "We meet customer needs by providing innovative and advanced solutions based on digital technology"
    },
    infoThree: {
        AR: `”الحياة فكرة“`,
        EN: `“Life is an idea”`
    },
    start: {
        AR: "ابدا معنا",
        EN: "Start With Us"
    },

    // Services Page
    servicesSec: {
        AR: "خدماتنا",
        EN: "Our Services"
    },
    servicesInfo: {
        AR: "تحويل أفكارك إلى واقع رقمي: حلولنا تجعل الابتكار ممكنًا",
        EN: "Transform your ideas into a digital reality: Our solutions make innovation possible"
    },
    vision: {
        AR: "رؤيتنا",
        EN: "Our Vision"
    },
    visionInfo: {
        AR: `                        نؤمن دائماً بأن الفكرة قادرة على تغيير ملامح المستقبل ، لذلك نحن فى Osbash نرى أن نصف مسار النجاح هو إيجاد الفكرة المختلفة واغتنامها .
`,
        EN: "We always believe that an idea is capable of changing the features of the future, so we at Osbash believe that half the path to success is finding a different idea and seizing it."
    },

    showMore: {
        AR: "تعرف علينا اكثر",
        EN: "Show More"
    },
    ideas: {
        AR: "حلول التقنية المبتكرة",
        EN: "Innovative technology solutions"
    },

    watchMore: {
        AR: "شاهد المزيد",
        EN: "Watch More"
    },

    Comments: {
        AR: "التعليقات والآراء",
        EN: "Comments and opinions"
    },
    commentInfo: {
        AR: "آراء وتعليقات عملائنا السابقين",
        EN: "Opinions and comments from our previous customers"
    },
    commentOne: {
        AR: "فريق عمل محترم وفاهم شغله ومتعاون",
        EN: "A respectful work team that understands its work and is cooperative",
    },
    partners: {
        AR: "شركائنا",
        EN: "Our partners"
    },
    partnerOne: {
        AR: "شركاء النجاح في كل خطوة",
        EN: "Success partners at every step"
    },
    uiux: {
        AR: "  تعريف بواجهة المستخدم, تجربة المستخدم - UI/UX",
        EN: "Definition of user interface, user experience - UI/UX"
    },
    uiuxInfo: {
        AR: `تجربة المستخدم User Experience (UX) هي أحد المفاهيم الرئيسية في مجال تصميم وتطوير الواجهات والتطبيقات. إنها تمثل المجموع الشامل للتفاعلات والانطباعات التي يكتسبها المستخدم عندما يتفاعل مع منتج ما أو أي نوع آخر من المنتجات الرقمية...
`,
        EN: `
        User Experience (UX) is one of the main concepts in the field of design and development of interfaces and applications. It represents the comprehensive sum of interactions and impressions that a user gains when interacting with a product or any other type of digital product...

        `
    },

    projectOwnerData: {
        AR: "إذا كنت صاحب فكرة أو مشروع قائم بالفعل وتريد تدعيم هذا النشاط بأفكار جديد",
        EN: "If you have an existing idea or project and want to support this activity with new ideas"
    },

    projectData: {
        AR: " إذا كنت ترغب في مساعدتك بفكرة أو مشروع",
        EN: `If you would like to help you with an idea or project
`
    },


    // Footer Translations
    footerCompanyDesc: {
        AR: "شركة اوسباش للحلول الرقمية, منشائة منذ 2021 فى محاولة للدخول الى العالم الرقمى كمطورين وليس كمستخدمين, كما اننا ساهمنا فى الكثير من الأعمال البرمجية لكبرى الشركات الدولية, وللعديد من الافراد على المستوى العالمى.",
        EN: "Osbash Digital Solutions Company, established in 2021 in an attempt to enter the digital world as developers, not users. We have contributed to many programming works for major international companies and individuals globally."
    },
    quickLinks: {
        AR: "روابط سريعه",
        EN: "Quick Links"
    },

    aboutUs: {
        AR: "من نحن",
        EN: "About Us"
    },
    privacyPolicy: {
        AR: "سياسة الخصوصية والإستخدام",
        EN: "Privacy Policy & Terms"
    },
    faq: {
        AR: "أسئلة متكررة",
        EN: "FAQ"
    },
    technicalSupport: {
        AR: "الدعم الفني",
        EN: "Technical Support"
    },
    aboutOsbash: {
        AR: "عن أوسباش",
        EN: "About Osbash"
    },
    visionValues: {
        AR: "رؤيتنا و قيمنا",
        EN: "Our Vision & Values"
    },
    teamPartners: {
        AR: "فريق العمل و الشركاء",
        EN: "Team & Partners"
    },
    copyright: {
        AR: "©2024.Osbash.e. جميع الحقوق محفوظة",
        EN: "©2024.Osbash.e. All rights reserved"
    },


    // Register Page Translations
    welcome: {
        AR: "مرحبا بكم في",
        EN: "Welcome to"
    },
    email: {
        AR: "الايميل",
        EN: "Email"
    },
    username: {
        AR: "اسم المستخدم",
        EN: "Username"
    },
    password: {
        AR: "كلمة السر",
        EN: "Password"
    },
    phone: {
        AR: "رقم الهاتف",
        EN: "Phone Number"
    },
    country: {
        AR: "الدوله",
        EN: "Country"
    },
    address: {
        AR: "العنوان",
        EN: "Address"
    },
    role:{
        AR: "الدور",
        EN:"Role"
    },
    job: {
        AR: "الوظيفه",
        EN: "Job"
    },
    register: {
        AR: "تسجيل",
        EN: "Register"
    },
    loading: {
        AR: "تحميل",
        EN: "Loading"
    },
    haveAccount: {
        AR: "هل لديك حساب ؟",
        EN: "Already have an account?"
    },
    login: {
        AR: "تسجيل الدخول",
        EN: "Sign In"
    },
    noAccount: {
        AR: "ليس لديك حساب؟",
        EN: "Don't have an account?"
    },
    signUpNow: {
        AR: "اشترك الان",
        EN: "Sign Up Now"
    },
    reg: {
        AR: "التسجيل",
        EN: "Register"
    },
    contactWithUs: {
        AR: "تواصل بنا",
        EN: "Contact with us"
    },
    haveQue: {
        AR: "إذا كان لديك أستفسار تواصل معنا",
        EN: "If you have any questions, contact us"
    },
    confirmEmail: {
        AR: "تاكيد الايميل",
        EN: "Confirm Email"
    },
    contactNum: {
        AR: "رقم التواصل",
        EN: "Contact Number"
    },
    message: {
        AR: "الرساله",
        EN: "Message"
    },
    save: {
        AR: "حفظ",
        EN: "Save"
    },
    cancel: {
        AR: "الغاء",
        EN: "Cancel"
    },
    or: {
        AR: "او",
        EN: "OR"
    },
    contactViaSocial: {
        AR: "تواصل معنا عبر وسائل التواصل",
        EN: "Contact us via social media"
    },

    blogs: {
        AR: "المدونه",
        EN: "Blogs"
    },

    modernBlog: {
        AR: "احدث المقالات",
        EN: "Latest Articles"
    },
    view: {
        AR: "مشاهدات",
        EN: "Views"
    },
    comments: {
        AR: "التعليقات",
        EN: "Comments"
    },
    allWorks: {
        AR: "شاهد الأعمال الكاملة له لأوسباش",
        EN: "See his complete works by Osbash"
    },

    // Policy Page Translations
    policyTitle: {
        AR: "سياسة الخصوصية والاستخدام لشركة Osbash",
        EN: "Privacy Policy and Terms of Use for Osbash"
    },
    policyIntro: {
        AR: "تعتبرسياسة الخصوصية والاستخدام وثيقة مهمة تحدد كيفية جمع البيانات واستخدامها ومشاركتها من قبل الشركة، وتوفير الحماية والسرية للمعلومات الشخصية التي تقدمها المستخدمين. تشمل عادةً هذه السياسة العناصر التالية:",
        EN: "The privacy and usage policy is an important document that defines how data is collected, used, and shared by the company, providing protection and confidentiality for personal information provided by users. This policy typically includes the following elements:"
    },
    policyPoints: {
        point1: {
            AR: "جمع المعلومات: توضح سياسة الخصوصية كيفية جمع المعلومات من المستخدمين، سواء كان ذلك من خلال موقع الويب أو التطبيقات أو غيرها من الوسائل الرقمية.",
            EN: "Information Collection: The privacy policy explains how information is collected from users, whether through the website, applications, or other digital means."
        },
        point2: {
            AR: "نطاق البيانات: تحدد السياسة أنواع المعلومات التي يتم جمعها، مثل البيانات الشخصية مثل الأسماء وعناوين البريد الإلكتروني، والمعلومات غير الشخصية مثل بيانات الاستخدام والارتباطات.",
            EN: "Data Scope: The policy defines the types of information collected, such as personal data like names and email addresses, and non-personal information like usage data and cookies."
        },
        point3: {
            AR: "استخدام المعلومات: توضح السياسة كيفية استخدام المعلومات التي يتم جمعها، سواء لأغراض تحسين الخدمات، أو تخصيص الإعلانات، أو تقديم خدمات جديدة.",
            EN: "Information Usage: The policy explains how collected information is used, whether for service improvement, ad customization, or providing new services."
        },
        point4: {
            AR: "مشاركة المعلومات: تحدد السياسة ما إذا كانت الشركة ستشارك المعلومات مع أطراف ثالثة، مثل الشركاء التجاريين أو الجهات الحكومية، وفي أي ظروف يتم ذلك.",
            EN: "Information Sharing: The policy specifies whether the company will share information with third parties, such as business partners or government entities, and under what circumstances."
        },
        point5: {
            AR: "حماية البيانات: توضح السياسة الإجراءات التي تتخذها الشركة لحماية البيانات من الوصول غير المصرح به أو الاستخدام غير القانوني.",
            EN: "Data Protection: The policy explains the procedures taken by the company to protect data from unauthorized access or illegal use."
        },
        point6: {
            AR: "حقوق المستخدمين: توفر السياسة معلومات حول حقوق المستخدمين فيما يتعلق بالوصول إلى بياناتهم الشخصية، وتصحيحها، وحذفها.",
            EN: "User Rights: The policy provides information about users' rights regarding accessing, correcting, and deleting their personal data."
        },
        point7: {
            AR: "تحديثات السياسة: يجب أن توضح السياسة كيفية تحديثها وتعديلها عند الضرورة، وكيفية إعلام المستخدمين بأي تغييرات.",
            EN: "Policy Updates: The policy must explain how it is updated and modified when necessary, and how users are notified of any changes."
        }
    },

    noWorks: {
        AR: "لا توجد أعمال",
        EN: "There are no works"
    },
    // Common Questions Translations
    commonQuestions: {
        AR: "الاسئله الشائعه :",
        EN: "Frequently Asked Questions:"
    },
    questions: {
        q1: {
            AR: "سياسة الخصوصية",
            EN: "Privacy Policy"
        },
        q2: {
            AR: "الخدمات التي نوفرها",
            EN: "Services We Provide"
        },
        q3: {
            AR: "ما هي خبرة الشركة في المجال",
            EN: "What is the company's experience in the field"
        },
        q4: {
            AR: "هل لديكم شهادات أو توصيات من عملاء سابقين؟",
            EN: "Do you have certificates or recommendations from previous clients?"
        },
        q5: {
            AR: "كيف يمكنني الاتصال بفريق الدعم الفني في حالة وجود مشكلة؟",
            EN: "How can I contact the technical support team if there is a problem?"
        },
        q6: {
            AR: "هل لديكم خطط للتطوير أو التحديث المستقبلي؟",
            EN: "Do you have plans for future development or updates?"
        },
        q7: {
            AR: "ما هي الأنظمة أو المعايير التي تلتزمون بها في العمل؟",
            EN: "What systems or standards do you adhere to in your work?"
        }
    },
    technicalSupportTitle: {
        AR: "الدعم الفني :",
        EN: "Technical Support:"
    },
    technicalSupportIntro: {
        AR: "خدمة الدعم الفني لشركة Osbash تعتبر جزءًا أساسيًا من تقديم الخدمات والمنتجات الرقمية بشكل فعال وموثوق. تهدف خدمة الدعم الفني إلى مساعدة العملاء في حل المشاكل التقنية وتوفير المساعدة والدعم في حالة وجود أية استفسارات أو مشاكل تتعلق بالمنتجات أو الخدمات التي تقدمها الشركة إليك :",
        EN: "Osbash's technical support service is an essential part of delivering digital services and products effectively and reliably. The technical support service aims to help customers solve technical problems and provide assistance and support in case of any inquiries or problems related to the products or services provided by the company to you:"
    },
    supportPoints: {
        point1: {
            AR: "قنوات الاتصال: تتضمن قنوات الاتصال الرئيسية عادة البريد الإلكتروني، والهاتف، والدردشة المباشرة على الموقع الإلكتروني أو التطبيق. يجب أن تكون هذه القنوات متاحة للعملاء على مدار الساعة وعلى مدار الأسبوع.",
            EN: "Communication channels: The main communication channels usually include email, phone, and live chat on the website or application. These channels should be available to customers 24/7."
        },
        point2: {
            AR: "فريق الدعم: يجب أن يكون لدى الشركة فريق مؤهل ومدرب جيدًا يمتلك المعرفة الفنية اللازمة لحل المشاكل بفعالية وبسرعة. يجب على أعضاء الفريق أن يكونوا وديين ومتعاونين مع العملاء.",
            EN: "Support team: The company must have a qualified and well-trained team with the necessary technical knowledge to solve problems effectively and quickly. Team members must be friendly and cooperative with customers."
        },
        point3: {
            AR: "مراقبة الأداء: يتم تتبع أداء خدمة الدعم الفني بشكل منتظم، وتقييم جودة الخدمة المقدمة وفقًا للمعايير المحددة مسبقًا. يمكن استخدام ملاحظات العملاء و الرضا لتحسين الأداء.",
            EN: "Performance monitoring: Technical support service performance is regularly tracked, and service quality is evaluated according to predetermined standards. Customer feedback and satisfaction can be used to improve performance."
        },
        point4: {
            AR: "قواعد المعرفة والموارد: يمكن أن تتضمن خدمة الدعم الفني قواعد المعرفة والموارد عبر الإنترنت لمساعدة العملاء في العثور على الحلول لمشاكلهم بشكل ذاتي.",
            EN: "Knowledge bases and resources: Technical support service can include online knowledge bases and resources to help customers find solutions to their problems independently."
        },
        point5: {
            AR: "تحديثات وتطوير الخدمة: يجب أن تتواصل الشركة مع العملاء وتقدم لهم التحديثات والتطويرات اللازمة للمنتجات أو الخدمات، بالإضافة إلى توفير التدريب على الاستخدام الصحيح والفعال.",
            EN: "Service updates and development: The company must communicate with customers and provide them with necessary updates and improvements to products or services, in addition to providing training on proper and effective use."
        },
        point6: {
            AR: "مراقبة الشكاوى والملاحظات: يجب أن تتبع الشركة وتحلل الشكاوى والملاحظات من العملاء لتحسين الخدمة وتلافي المشاكل المتكررة.",
            EN: "Monitoring complaints and feedback: The company must track and analyze complaints and feedback from customers to improve service and avoid recurring problems."
        }
    },
    // Team Page Translations
    teamTitle: {
        AR: "فريق جماعي - عقول أبداعية",
        EN: "Collective Team - Creative Minds"
    },
    teamIntro: {
        AR: "يتميز فريق Osbash بتنوع المهارات والخبرات، والقدرة على التعاون وتبادل الأفكار لإنتاج أفكار وحلول مبتكرة وفعالة. يتميز الفريق بالتفاني في التحدي والابتكار، ويعمل بروح الفريق الواحد لتحقيق الأهداف المحددة وتجاوز التحديات التي تواجهه.",
        EN: "The Osbash team is distinguished by diverse skills and experiences, with the ability to collaborate and exchange ideas to produce innovative and effective solutions. The team is characterized by dedication to challenge and innovation, working with team spirit to achieve specific goals and overcome challenges."
    },
    teamPoints: {
        point1: {
            AR: "التنوع في المهارات: يتكون الفريق من أفراد يتمتعون بمهارات وخبرات متنوعة في مجالات مختلفة، مما يسمح بتغطية مجموعة واسعة من الاحتياجات والتحديات.",
            EN: "Skill Diversity: The team consists of individuals with varied skills and experiences in different fields, allowing coverage of a wide range of needs and challenges."
        },
        point2: {
            AR: "التفكير الجماعي: يتميز الفريق بالقدرة على تبادل الأفكار والتعاون لإيجاد حلول مبتكرة وإبداعية للمشكلات المطروحة.",
            EN: "Collective Thinking: The team excels in exchanging ideas and collaborating to find innovative and creative solutions to presented problems."
        },
        point3: {
            AR: "التحفيز والإلهام: يتم تشجيع الأعضاء على التفكير بشكل خلاق والتجريب بأفكار جديدة، مما يساعد على توليد المزيد من الأفكار الابتكارية.",
            EN: "Motivation and Inspiration: Members are encouraged to think creatively and experiment with new ideas, helping generate more innovative concepts."
        },
        point4: {
            AR: "مشاركة المعلومات: تحدد السياسة ما إذا كانت الشركة ستشارك المعلومات مع أطراف ثالثة، مثل الشركاء التجاريين أو الجهات الحكومية، وفي أي ظروف يتم ذلك.",
            EN: "Information Sharing: The policy determines if and when the company will share information with third parties, such as business partners or government entities."
        },
        point5: {
            AR: "التوازن بين القيادة والمشاركة: يعتمد الفريق على تواجد قادة يتولون المسؤولية ويوجهون الفريق، بالإضافة إلى تشجيع المشاركة الفعّالة لجميع الأعضاء.",
            EN: "Balance between Leadership and Participation: The team relies on leaders who take responsibility and guide the team, while encouraging active participation from all members."
        },
        point6: {
            AR: "الاحترام والثقة: يسود في الفريق جو من الاحترام المتبادل والثقة، مما يسهم في بناء بيئة عمل إيجابية ومشجعة للإبداع والتعاون.",
            EN: "Respect and Trust: The team maintains an atmosphere of mutual respect and trust, contributing to a positive work environment that encourages creativity and collaboration."
        },
        point7: {
            AR: "تحقيق الأهداف: يتميز الفريق بالتفاني والتحدي في تحقيق الأهداف المحددة، وتجاوز العقبات التي تواجهه، مع الحرص على تحقيق النتائج الممتازة.",
            EN: "Achieving Goals: The team is distinguished by dedication and challenge in achieving set objectives, overcoming obstacles, while ensuring excellent results."
        }
    },
    clientsTitle: {
        AR: "عملاء Osbash",
        EN: "Osbash Clients"
    },
    clientsIntro: {
        AR: "يتنوع عملاء Osbash بشكل كبير حسب نوع الخدمات التي تقدمها الشركة ومجال الذي يطلبه العميل . ومن بين هؤلاء العملاء قد يتضمنون:",
        EN: "Osbash clients vary greatly depending on the type of services provided by the company and the field requested by the client. Among these clients may include:"
    },
    clientTypes: {
        type1: {
            AR: "أصحاب الأفكار والمشروعات :حيث تحتاج هذه الشركات او الأفراد إلى خدمات مثل تصميم وتطوير المواقع الإلكترونية، وإدارة وسائل التواصل الاجتماعي، والتسويق الرقمي لتعزيز وجودها على الإنترنت وجذب المزيد من العملاء ـ وتكامل الأنظمة لتلبية احتياجاتهم بصناعات مختلفة.",
            EN: "Idea and Project Owners: These companies or individuals need services such as website design and development, social media management, digital marketing to enhance their online presence and attract more customers, and system integration to meet their needs in different industries."
        },
        type2: {
            AR: "من يرغبون بأنشاء مشروع خاص بهم : نحن في Osbash نؤمن دائماً بأن الفكرة قادرة على تغيير ملامح المستقبل ، لذلك نحن فى اوسباش نرى أن نصف مسار النجاح هو إيجاد الفكرة المختلفة واغتنامها لذا اذا كنت ترغب في إنشاء مشروع ولاتعرف بالتحديد ما الخدمة التي تريدها فلا تتردد في التواصل معنا سنقدم لك أفكار مشاريع كاملة ومربحة بدون رأس مال",
            EN: "Those wanting to start their own project: At Osbash, we always believe that an idea can change the features of the future. Therefore, we at Osbash see that half the path to success is finding and seizing the different idea. So if you want to start a project and don't know exactly what service you want, don't hesitate to contact us - we will provide you with complete and profitable project ideas without capital."
        }
    },
    // Service Request Translations
    requestService: {
        AR: "هل ترغب في طلب الخدمة ؟",
        EN: "Would you like to request a service?"
    },
    scheduleInfo: {
        AR: "حدد موعد مع المديرين لتقييم أحتياجاتك ومناقشة أساليب تنفيذها",
        EN: "Schedule a meeting with managers to assess your needs and discuss implementation methods"
    },
    bookAppointment: {
        AR: "احجز موعدا",
        EN: "Book an Appointment"
    },
    whatsapp: {
        AR: "رقم الواتساب",
        EN: "WhatsApp Number"
    },
    name: {
        AR: "الاسم",
        EN: "Name"
    },
    selectService: {
        AR: "اختر الخدمه",
        EN: "Select Service"
    },
    serviceFormTitle: {
        AR: "نموذج طلب خدمات اوسباش",
        EN: "Osbash Services Request Form"
    },
    statNow:{
        AR:"ابدا الان",
        EN:"Start Now"
    },
    requestService:{
        AR:'طلب الخدمة',
        EN:'Request'
    },
    Send:{
        AR:'ارسال',
        EN:'send'
    },
    RequestServiceResponseSuccess:{
        AR:'شكرا لتواصلكم معنا ,سيتم التواصل معك فى اقرب وقت.',
        EN:'Thank you for your request, Will contact you very soon.'
    },
    profile: {
        AR: "الملف الشخصي",
        EN: "Profile"
    },
    messages: {
        AR: "الرسائل",
        EN: "Messages"
    },
    notifications: {
        AR: "الاشعارات",
        EN: "Notifications" 
    },
    service: {
        AR: "متابعة الخدمه",
        EN: "Service Follow-up"
    },
    orders: {
        AR: "الطلبات",
        EN: "Orders"
    },
    settings: {
        AR: "الإعدادات",
        EN: "Settings"
    },
    support: {
        AR: "الدعم الفني",
        EN: "Technical Support"
    },
    abouttYou:{
        AR:"نبذه عنك",
        EN:"About You"
    },
    newMessage:{
        AR:"ارسال رساله جديده",
        EN:"Send New Message"
    },
    startDate:{
        AR:"تاريخ البدء",
        EN:"Start Date"
    },
    endDate:{
        AR:"تاريخ الانتهاء",
        EN:"End Date"
    },
    finalDate:{
        AR:"الموعد النهائي",
        EN:"Final Date"
    },
    date:{
        AR:"التاريخ",
        EN:"Date"
    },
    price:{
        AR:"السعر",
        EN:"Price"
    }
};
