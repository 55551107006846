import React, { useEffect } from 'react'
import { useState } from 'react';
import { IoMdAdd } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css';
import { ErrorMsg, success } from '../../shared/Modules/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { Create_New_service } from '../../../actions/serviceAction';
import { Create_New_project } from '../../../actions/projectsAction';
import { getAllClients, urlBase } from '../../../utils/API';
const AddProjectModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const serviceData = useSelector((state) => state.serviceReducer);
    const handleShow = () => setShow(true);
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [status, setStatus] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [deadLine, setDeadLine] = useState("");
    const [image, setImage] = useState("");
    const [service, setService] = useState("");
    const [client, setClient] = useState("");

    const [stages, setStages] = useState([{
        name: '',
        description: '',
        date: '',
        status: 'completed'
    }]);

    const [paymentStages, setPaymentStages] = useState([{
        name: '',
        description: '',
        price: '',
        discount: '',
        date: '',
        status: 'unpaid'
    }]);

    const handleAddStage = () => {
        setStages([...stages, {
            name: '',
            description: '',
            date: '',
            status: 'completed'
        }]);
    };

    const handleAddPaymentStage = () => {
        setPaymentStages([...paymentStages, {
            name: '',
            description: '',
            price: '',
            discount: '',
            date: '',
            status: 'unpaid'
        }]);
    };


    const handleStageChange = (index, field, value) => {
        const newStages = [...stages];
        newStages[index][field] = value;
        setStages(newStages);
    };

    const handlePaymentStageChange = (index, field, value) => {
        const newPaymentStages = [...paymentStages];
        if (field === 'price' || field === 'discount') {
            newPaymentStages[index][field] = Number(value);
        } else {
            newPaymentStages[index][field] = value;
        }
        setPaymentStages(newPaymentStages);
    };



    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();


    function uploadingFileLocally(data) {
        const formData = new FormData();
        formData.append("file", data)
        return axios({
            method: "POST",
            url: `${urlBase}/api/uploads/single`,
            data: formData,
        })
    }




    // Add this validation state
    const [validationErrors, setValidationErrors] = useState({
        name: false,
        desc: false,
        status: false,
        service: false,
        client: false
    });

    // Add validation check function
    const validateRequiredFields = () => {
        const errors = {
            name: !name.trim(),
            desc: !desc.trim(),
            status: !status,
            service: !service,
            client: !client,
        };
        setValidationErrors(errors);
        return !Object.values(errors).some(error => error);
    };


    const [clients, setClinets] = useState([]);
    useEffect(() => {
        const handleGetUsers = async () => {
            try {
                const res = await getAllClients();
                console.log(res)
                console.log({Results_:res?.data?.results_});
                if (res?.status === 200) {
                    setClinets(res?.data?.results)
                }
            } catch (e) {
                console.log(e)
            }
        }
        handleGetUsers();
    }, [])




    const handleAddProject = async () => {
        try {
            if (!validateRequiredFields()) {
                ErrorMsg("Please fill in all required fields");
                return;
            }
            setLoading(true)
            // if (image) {
            //     const res = await uploadingFileLocally(image);
            //     console.log(res)
            //     if (res?.status === 201) {
            //         const resPonse = await dispatch(Create_New_project(
            //             {
            //                 name: name,
            //                 description: desc,
            //                 status: status,
            //                 startDate: startDate,
            //                 endDate: endDate,
            //                 deadline: deadLine,
            //                 coverImage: res?.data?.filename,
            //                 client: client,
            //                 // stages: stages,
            //                 clientComment: "Client Comment",
            //                 team: [
            //                     "66ebf1698e5c5964fabd12ff"
            //                 ],
            //                 service: service,
            //                 // paymentStages: paymentStages
            //             }
            //         ))

            //         if (resPonse?.status === 201) {
            //             success("تم اضافة المشروع بنجاح");
            //             handleClose();
            //         }

            //     }
            // } else {
            //     ErrorMsg("Please upload an image");
            // }
            if (image) {
                const res = await uploadingFileLocally(image);
                if (res?.status === 201) {
                    const projectData = {
                        name: name,
                        description: desc,
                        status: status,
                        startDate: startDate,
                        endDate: endDate,
                        deadline: deadLine,
                        coverImage: res?.data?.filename,
                        client: client,
                        clientComment: "Client Comment",
                        team: ["66ebf1698e5c5964fabd12ff"],
                        service: service,
                    };

                    // Add stages only if they have data
                    if (stages.some(stage => stage.name || stage.description || stage.date)) {
                        projectData.stages = stages;
                    }

                    // Add paymentStages only if they have data
                    if (paymentStages.some(stage => stage.name || stage.description || stage.price || stage.discount)) {
                        projectData.paymentStages = paymentStages;
                    }

                    const resPonse = await dispatch(Create_New_project(projectData));
                    if (resPonse?.status === 201) {
                        success("تم اضافة المشروع بنجاح");
                        handleClose();
                    }
                }
            }

        } catch (e) {
            console.log(e)
            if (e?.response?.data?.message) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.forEach(msg => {
                        ErrorMsg(msg);
                    });
                } else {
                    ErrorMsg(e.response.data.message);
                }
            }
        } finally {
            setLoading(false)
        }
    }



    return (
        <div>
            {/* <button className='btn sharedBtn sharedHover' >
                ابدا الان
            </button> */}

            <IoMdAdd size="35px" color="#456091" onClick={handleShow} />
            <Modal show={show} onHide={handleClose} centered >
                <div style={{ backgroundColor: "#4973BC", color: "white", textAlign: "center" }}>
                    <p style={{ textAlign: "center", padding: "10px" }}>اضافة مشروع</p>
                </div>
                <div className="modalServicesBody">
                    <div>
                        <div>
                            <label>اسم المشروع
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input type="text" name="name" id="name" className="form-control"
                                value={name}
                                style={{ borderColor: validationErrors.name ? 'red' : '' }}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setValidationErrors({ ...validationErrors, name: false });
                                }}
                            />
                        </div>
                        <div>
                            <label>وصف المشروع
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input type="text" name="name" id="name" className="form-control"
                                value={desc}
                                style={{ borderColor: validationErrors.name ? 'red' : '' }}
                                onChange={(e) => {
                                    setDesc(e.target.value);
                                    setValidationErrors({ ...validationErrors, desc: false });
                                }}
                            />
                        </div>
                        <div>
                            <label>صورة المشروع</label>
                            <input type="file" name="fileImage" id="fileImage" className="form-control"
                                onChange={(event) => {
                                    setImage(event.target.files[0]);
                                    const url = window.URL.createObjectURL(event.target.files[0]);
                                }}
                            />
                        </div>
                        <div>
                            <label>تاريخ البدايه</label>
                            <input type="date" name="name" id="name" className="form-control"
                                onChange={(e) => setStartDate(e.target.value)} value={startDate}
                            />
                        </div>
                        <div>
                            <label>تاريخ النهايه</label>
                            <input type="date" name="price" id="price" className="form-control"
                                onChange={(e) => setEndDate(e.target.value)} value={endDate}
                            />
                        </div>
                        <div>
                            <label>الوقت المحدد لانهاء المشروع</label>
                            <input type="date" name="price" id="price" className="form-control"
                                onChange={(e) => setDeadLine(e.target.value)} value={deadLine}
                            />
                        </div>


                        <div>
                            <label>العميل
                                <span style={{ color: 'red' }}>*</span>
                            </label>

                            <select
                                style={{ borderColor: validationErrors.name ? 'red' : '' }}
                                className="form-control" onChange={(e) => {
                                    setClient(e.target.value)
                                    setValidationErrors({ ...validationErrors, client: false });

                                }

                                } value={client}>
                                <option hidden>Client</option>
                                {clients.length&&clients?.map((client) => (
                                    <option key={client._id} value={client._id}>
                                        {client.name}
                                    </option>
                                ))}
                            </select>

                        </div>


                        <div>
                            <label>حالة العمل
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <select
                                style={{ borderColor: validationErrors.name ? 'red' : '' }}
                                className="form-control" onChange={(e) => {
                                    setStatus(e.target.value)
                                    setValidationErrors({ ...validationErrors, status: false });

                                }

                                } value={status}>
                                <option hidden>Status</option>
                                <option value={"inprogress"}>inprogress</option>
                                <option value={"waiting"}>waiting</option>
                                <option value={"completed"}>completed</option>
                            </select>
                        </div>
                        <div>
                            <label>دعم الفريق</label>
                            <select className="form-control" >
                                <option hidden>Status</option>
                                <option value={"active"}>Active</option>
                                <option value={"completed "}>completed </option>
                            </select>
                        </div>
                        <div>
                            <label>نوع الخدمه
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <select className="form-control"
                                style={{ borderColor: validationErrors.name ? 'red' : '' }}
                                onChange={(e) => {
                                    setService(e.target.value)
                                    setValidationErrors({ ...validationErrors, service: false });
                                }} value={service}>
                                <option hidden>Service Type</option>
                                {
                                    serviceData?.map((serviceData) => {
                                        return (
                                            <option key={serviceData?._id} value={serviceData?._id}>{serviceData.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        {/* Stage  && Payment */}
                        <div className="stages-section mt-4">
                            <h5>المراحل</h5>
                            {stages.map((stage, index) => (
                                <div key={index} className="stage-item border p-3 mb-3">
                                    <div className="form-group">
                                        <label>اسم المرحلة</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={stage.name}
                                            onChange={(e) => handleStageChange(index, 'name', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>الوصف</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={stage.description}
                                            onChange={(e) => handleStageChange(index, 'description', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>التاريخ</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={stage.date}
                                            onChange={(e) => handleStageChange(index, 'date', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>الحالة</label>
                                        <select
                                            className="form-control"
                                            value={stage.status}
                                            onChange={(e) => handleStageChange(index, 'status', e.target.value)}
                                        >
                                            <option value="waiting">waiting</option>
                                            <option value="inprogress">inprogress</option>
                                            <option value="completed">completed</option>
                                        </select>
                                    </div>
                                </div>
                            ))}
                            <button type="button" className="btn btn-secondary" onClick={handleAddStage}>
                                + إضافة مرحلة جديدة
                            </button>
                        </div>

                        {/* Payment Stages Section */}
                        <div className="payment-stages-section mt-4">
                            <h5>مراحل الدفع</h5>
                            {paymentStages.map((paymentStage, index) => (
                                <div key={index} className="payment-stage-item border p-3 mb-3">
                                    <div className="form-group">
                                        <label>اسم مرحلة الدفع</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={paymentStage.name}
                                            onChange={(e) => handlePaymentStageChange(index, 'name', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>الوصف</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={paymentStage.description}
                                            onChange={(e) => handlePaymentStageChange(index, 'description', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>السعر</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={paymentStage.price}
                                            onChange={(e) => handlePaymentStageChange(index, 'price', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>الخصم</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={paymentStage.discount}
                                            onChange={(e) => handlePaymentStageChange(index, 'discount', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>التاريخ</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={paymentStage.date}
                                            onChange={(e) => handlePaymentStageChange(index, 'date', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>حالة الدفع</label>
                                        <select
                                            className="form-control"
                                            value={paymentStage.status}
                                            onChange={(e) => handlePaymentStageChange(index, 'status', e.target.value)}
                                        >
                                            <option value="paid">paid</option>
                                            <option value="unpaid">unpaid</option>
                                        </select>
                                    </div>
                                </div>
                            ))}
                            <button type="button" className="btn btn-secondary" onClick={handleAddPaymentStage}>
                                + إضافة مرحلة دفع جديدة
                            </button>
                        </div>

                    </div>
                    <div className="mt-4" style={{ textAlign: "left" }}>
                        <button onClick={handleClose} className='btn mx-2' style={{ color: "#BF4148", backgroundColor: "white", border: "1px solid #BF4148", outline: "none" }}>
                            الغاء
                        </button>
                        <button disabled={loading} className='btn' onClick={handleAddProject} style={{ backgroundColor: "#4973BC", color: "white" }}>
                            {loading ? "تحميل" : "اضافه"}
                        </button>
                    </div>
                </div>



            </Modal>
        </div>
    )
}

export default AddProjectModal
